<template>
  <div class="container" id="container">
    <div class="top">
      <a href="javascript:;" @click="back" class="back"><img src="./img/black_back.png" alt="返回" /></a>
      <div class="sort">
        <div class="one">
          <div class="datebox" @click="showSelectDay = !showSelectDay">
            <li>{{$formatDate(new Date(dateArr[0]),"MM/dd")}}入住</li>
            <li>{{$formatDate(new Date(dateArr[1]),"MM/dd")}}退房</li>
          </div>
          <div class="inputbox">
            <input type="search" v-model="searchkey" :placeholder="'搜索附近'+typeName+'·关键字'" />
          </div>
          <a href="javascript:;" class="searchbtn" @click="reloadList"><img src="./img/icon-search.png" alt="搜索" /></a>
        </div>
        <div class="filter">
          <a href="javascript:;" @click="showSelectArea = !showSelectArea"><span>{{areaName}}</span><div style="text-align: left"><img src="./img/icon-dropdown.png" alt="筛选" /></div></a>
          <a href="javascript:;" @click="showSelectRating = !showSelectRating"><span>{{spText}}</span><div style="text-align: left"><img src="./img/icon-dropdown.png" alt="筛选" /></div></a>
          <a href="javascript:;" @click="showDistance = !showDistance"><span>{{distance}}</span><div style="text-align: left"><img src="./img/icon-dropdown.png" alt="筛选" /></div></a>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="boxname">目前有{{pageTotal}}处{{typeName}}<img src="./img/icon-bed.png" alt="床" /></div>
      <div class="list">
        <template v-if="hotels.length>0">
          <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
            <van-list
              :immediate-check="false"
              v-model="isLoading"
              :finished="finished"
              finished-text="没有更多了"
              @load="onLoadBottom"
            >
              <div class="hotel" v-for="(item, index) in hotels" :key="index">
                <div class="pic" :style = '{ background:"url("+ item.cover +") no-repeat center center/cover"}' @click.stop="goInner(item)">
                  <a href="javascript:;" :class="{'follow':item.collectId}" @click.stop="collect(item)">取消收藏</a>
                </div>
                <a  href="javascript:;" class="info" @click="goInner(item)">
                  <div class="title">
                    <div class="text">
                      <div class="name">{{item.hotelName}}</div>
                      <div class="type">·{{item.starLevelText}}</div>
                    </div>
                    <div class="price">￥{{item.minPirce ? item.minPirce/100 : 0}}起/晚</div>
                  </div>
                  <div class="distance">距离您{{item.distance ? (item.distance/1000).toFixed(2) : 0}}公里</div>
                  <address>{{item.address}}</address>
                </a>
              </div>
            </van-list>
          </van-pull-refresh>

        </template>
      </div>
    </div>
    <!--日期弹窗-->
    <chooseDateRange v-show="showSelectDay" @closeCalendar="showSelectDay = false" @saveDate="saveDate" :dateInfo="{isFull:true}"></chooseDateRange>
    <!--星级弹窗-->
    <starPrice v-if="showSelectRating" @close="showSelectRating = false" @save="saveSP"></starPrice>
    <!--区域弹窗-->
    <chooseArea v-if="showSelectArea" @close="showSelectArea = false"></chooseArea>
    <!--距离弹窗-->
    <chooseDistance v-if="showDistance" @closeDistance="showDistance = false"></chooseDistance>
  </div>
</template>

<script>
  import chooseDateRange from './component/chooseDateRange'
  import starPrice from './component/starPrice'
  import chooseArea from './component/chooseArea'
  import chooseDistance from './component/chooseDistance'
  import apis from './utils/apis'
  import { Toast, PullRefresh, List } from 'vant';
  export default {
    name: "hotelList",
    components:{
      chooseDateRange,
      starPrice,
      chooseArea,
      chooseDistance,
      'van-pull-refresh': PullRefresh,
      'van-list': List,
    },
    watch:{
      showSelectRating(n,o){
        if(!n){
          this.infoSP = sessionStorage.getItem('starPrice') ? JSON.parse(sessionStorage.getItem('starPrice')) : {}
          // this.reloadList();
        }
      },
      showSelectArea(n,o){
        if(!n){
          this.areaName = sessionStorage.getItem('area') ? JSON.parse(sessionStorage.getItem('area')).name : '峨眉山市'
          this.reloadList();
        }
      },
      showDistance(n,o){
        if(!n){
          this.distance = sessionStorage.getItem('distance') ? JSON.parse(sessionStorage.getItem('distance')).name : '距离'
          this.reloadList();
        }
      },
    },
    data() {
      return {
        areaName:sessionStorage.getItem('area') ? JSON.parse(sessionStorage.getItem('area')).name : '峨眉山市',
        pageSize: 10,
        pageIndex: 1,
        pageTotal: 0,
        loading: true, // 加载中
        showSelectDay: false, // 选择日期
        showSelectRating: false, // 选择星级
        searchkey: sessionStorage.getItem('searchkey') ? sessionStorage.getItem('searchkey') : '', // 搜索关键词
        location: null, // 位置
        userSelectDays: null,
        startDay: null, // 入住日期
        endDay: null, // 离开日期
        showSelectArea: false, // 区域选择
        showDistance: false, // 距离选择
        hotels: [],
        refreshing: false, //是否刷新
        isLoading:false,
        finished:false,
        infoSP: sessionStorage.getItem('starPrice') ? JSON.parse(sessionStorage.getItem('starPrice')) : {}, // 选择金额星级
        dateArr:sessionStorage.getItem('dateRage') ? JSON.parse(sessionStorage.getItem('dateRage')) : [new Date().getTime(),Number(new Date().getTime()+(24*3600*1000))],//选中的日期区间
        distance:sessionStorage.getItem('distance') ? JSON.parse(sessionStorage.getItem('distance')).name : '距离',//选中的日期区间
        lon:'',
        lat:'',
        isLoadMore:true,
        spText:'价格/星级',
        spValue:[],
        typeName:sessionStorage.getItem('hoteltype') == 'jd' ? '酒店' : '民宿',
      }
    },
    created() {
      this.getShareInfo(
        this.$global.shareTitle,
        this.$global.visitUrl,
        this.$global.shareImage,
        this.$global.shareIntro,
      );
      if(sessionStorage.getItem('starPrice')){
        this.delSPText();
      }else{
        this.getlocation();
      }
    },
    mounted() {   //监听scroll事件
      window.addEventListener('scroll', this.handleScroll, true)
    },
    destroyed() { //页面离开后销毁，防止切换路由后上一个页面监听scroll滚动事件会在新页面报错问题
      window.removeEventListener('scroll', this.handleScroll)
    },
    methods: {
      //上啦加载她
      onLoadBottom(){
       
        let me = this;
        this.pageIndex = this.pageIndex ? this.pageIndex + 1 : 1;
        me.getHotels();
      },
      //下拉刷新
      onRefresh(){
        this.reloadList();
      },
      //子组件保存星级价格done
      saveSP(info){
        this.showSelectRating = false;
        this.infoSP = JSON.parse(JSON.stringify(info));
        this.delSPText(1);
      },
      delSPText(num){
        let _t = this;
        _t.spValue = [];
        _t.hotels = [];
        let priceStr = _t.infoSP.price ? '￥'+ _t.infoSP.price[0]+'-'+ _t.infoSP.price[1] : '价格';
        let arr = [],starStr = '';
        _t.infoSP.star.map((item,index) => {
          if(item.acStar){
            arr.push(item.name);
            _t.spValue.push(item.value);
          }
        });
        starStr = arr.length ? arr.join(',') :'星级';
        _t.spText = priceStr + ',' + starStr;
        if(num){
          this.reloadList()
        }else{
          //创建组件时，加载第1页数据
          this.getlocation();
        }
      },
      goInner(row){
        this.$router.push({
          path:'/hotelInner',
          query:{
            sellerId:row.sellerId
          }
        })
      },
      reloadList(){
        
        this.hotels = [];
        this.finished = false;
        this.pageIndex = 1;
        this.getHotels();
      },
      // 收藏
      collect(row) {
        row.collectId ? this.cancelCollect(row) : this.addCollect(row);
      },
      addCollect(row){//添加收藏
        const me = this;
        me.$post({
          url: apis.addCollect,
          params:{
            token:me.$global.token,
            bizType: 3,
            bizId: row.sellerId
          }
        }).then(rsp => {
          // Toast('操作成功');
          row.collectId = 1

        })
      },
      cancelCollect(row){//取消收藏
        const me = this;
        me.$post({
          url: apis.cancelCollect,
          params:{
            token: me.$global.token,
            bizType: 3,
            bizId: row.sellerId
          }
        }).then(rsp => {
          // Toast('操作成功');
          row.collectId = null
        });
      },
      // 顶部后退
      back() {
        // sessionStorage.removeItem('searchkey');
        window.history.back()
      },
      //日历保存
      saveDate(info){
        this.dateArr = JSON.parse(JSON.stringify(info));
        this.showSelectDay = false;
        this.getHotels();
      },
      // 获取当前位置done
      getlocation() {
        let me = this,map = localStorage.getItem('map');
        if(map){
          let locations = JSON.parse(map).position;
          me.lat = locations.lat;
          me.lon =  locations.lng;
          me.getHotels();
        }else{
          me.getHotels();
        }
      },
      // 获取推荐酒店/民宿列表
      getHotels() {
        let _t = this;
        this.loading = true;
        sessionStorage.setItem('searchkey',this.searchkey);
        this.$get({
          url: apis.searchHotelList,
          params: {
            // isRecommend: 0, //是否推荐
            areaName:this.areaName,
            sortName:'sequence',
            sortOrder:'asc',
            token:this.$global.token,
            pageSize:10,
            pageIndex:this.pageIndex,
            startDate:this.$formatDate(new Date(this.dateArr[0]),'yyyy-MM-dd'),
            endDate:this.$formatDate(new Date(this.dateArr[1]),'yyyy-MM-dd'),
            typeCode:sessionStorage.getItem('hoteltype'),
            lon:this.lon || 103.470421,
            lat:this.lat || 29.584007,
            lowPrice:this.infoSP.price ? this.infoSP.price[0]*100 : '',
            highPrice:this.infoSP.price ? Number(this.infoSP.price[1])*100 : '',
            starLevel:this.spValue.join(','),
            distance:JSON.parse(sessionStorage.getItem('distance')) ? JSON.parse(sessionStorage.getItem('distance')).value : '',
            hotelName:this.searchkey,
          }
        }).then(rsp => {
          this.isLoading = false;
          if (_t.refreshing) {
            _t.refreshing = false;
          }
          this.pageTotal = rsp.count;
          if(rsp.data && rsp.data.length){
            this.hotels = this.hotels.concat(rsp.data);
          }
          let list = rsp.data;
          if (!list || list.length < 10) {
            _t.finished = true;
          }
        }).catch(err => {
          this.loading = false;
        });
      },
    }
  }
</script>

<style scoped>
.top{
  position: fixed;
  width: 100%;
  top: 0;
  background: #fff;
  box-shadow: 0 0 .2rem #ccc;
  font-size: 0;
  box-sizing: border-box;
  padding: .4rem .4rem .3rem 0;
  z-index: 1;
}
.top .back{
  width: 1.5rem;
  height: 1.45rem;
  display: inline-block;
  vertical-align: top;
  text-align: center;
  line-height: 1.45rem;
}
.top img{
  height: 40%;
  display: inline-block;
  vertical-align: middle;
}
.top .sort{
  width: calc(100% - 1.5rem);
  display: inline-block;
  vertical-align: top;
}
.top .sort .one{
  box-shadow: 0 0 .15rem #ccc;
  border-radius: 5px;
  padding: .1rem 0;
}
.top .sort .datebox{
  width: 2.5rem;
  display: inline-block;
  vertical-align: top;
  text-align: center;
  cursor: pointer;
}
.top .sort .datebox li{
  font-size: .4rem;
  line-height: .6rem;
  list-style: none;
  color: #20C177;
}
.top .sort .inputbox{
  width: 4.8rem;
  display: inline-block;
  vertical-align: top;
  line-height: 1.2rem;
}
.top .sort .inputbox input{
  width: 100%;
  display: inline-block;
  box-sizing: border-box;
  border: none;
  border-left: 1px solid #ddd;
  height: 1rem;
  vertical-align: middle;
  padding: 0 .1rem 0 .3rem;
  font-size: .4rem;
}
.top .sort .searchbtn{
  width: .7rem;
  display: inline-block;
  vertical-align: top;
  text-align: left;
  height: 1.2rem;
  line-height: 1.2rem;
}
.top .sort .searchbtn img{
  width: 80%;
  height: auto;
  display: inline-block;
  vertical-align: middle;
}
.top .sort .filter {
  margin-top: .4rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.top .sort .filter a{
  width: 33%;
  height: .5rem;
  vertical-align: middle;
  font-size: .4rem;
  text-align: center;
  color: #222;
  line-height: .5rem;
  display: flex;
  align-items: center;
  padding: 0 10px;
}
.top .sort .filter a span{
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: right;
}
.top .sort .filter a:first-of-type{
  text-align: left;
}
.top .sort .filter a:last-of-type{
  text-align: right;
}
.top .sort .filter a:nth-of-type(2){
  width: 46%;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.top .sort .filter a img{
  height: .25rem;
  vertical-align: middle;
  margin-left: .1rem;
}
.container .content{
  padding: 3rem .4rem .2rem .4rem;
}
.container .content .boxname{
  font-size: .5rem;
  line-height: .5rem;
  padding: .6rem 0 0 0;
}
.container .content .boxname img{
  height: .5rem;
  vertical-align: middle;
  margin-left: .2rem;
}
.container .content .hotel{
  box-shadow: 0 0 .2rem #aaa;
  border-radius: 0 0 .2rem .2rem;
  font-size: 0;
}
.container .content .hotel .pic{
  height: 4rem;
  display: block;
  margin-top: .4rem;
  position: relative;
  /* border-radius: .2rem .2rem 0 0; */
}
.container .content .hotel .pic a{
  position: absolute;
  right: .1rem;
  top: .1rem;
  width: 1rem;
  height: 1rem;
  /*background: url('img/icon-nofollow.png') no-repeat center center /50% auto;*/
  font-size: 0;
}
.container .content .hotel .pic a.follow{
  /*background: url('img/icon-follow.png') no-repeat center center /50% auto;*/
}
.container .content .hotel .info{
  padding: .2rem;
  display: block;
  color: #333;
}
.container .content .hotel .info .title{
  white-space: nowrap;
  line-height: .8rem;
}
.container .content .hotel .info .text{
  width: calc(100% - 2.5rem);
  display: inline-block;
  vertical-align: top;
}
.container .content .hotel .info .text .name{
  display: inline-block;
  max-width: calc(100% - 2rem);
  vertical-align: top;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: .4rem;
}
.container .content .hotel .info .text .type{
  display: inline-block;
  width: 2rem;
  vertical-align: top;
  font-size: .35rem;
}
.container .content .hotel .info .price{
  width: 2.5rem;
  display: inline-block;
  vertical-align: top;
  font-size: .4rem;
  text-align: right;
}
.container .content .hotel .info .distance,.container .content .hotel .info address{
  font-size: .33rem;
  line-height: .5rem;
  font-style: normal;
}
.container .loading{
  font-size: .4rem;
  padding: .2rem;
  color: #ccc;
  text-align: center;
}
.container .loading img{
  height: .4rem;
  vertical-align: middle;
  margin: -.1rem .1rem 0 0;
  animation: loading 0.5s linear infinite;
}
/*子菜单显示动画*/
@keyframes loading {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(180deg);
  }
}
</style>
